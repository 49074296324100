import { merge } from 'theme-ui'
// @ts-ignore - There are no types
import originalTheme from '@lekoarts/gatsby-theme-jodie/src/gatsby-plugin-theme-ui/index'
import tailwind from '@theme-ui/preset-tailwind'

const theme = merge(originalTheme, {
  fonts: {
    body: `"Noto Sans JP", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  },
  colors: {
    ...tailwind.colors,
  },
  badges: {
    skills: {
      color: tailwind.colors.background,
    },
  },
})
export default theme
